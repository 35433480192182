import React,{useState} from 'react';
import './index.css'
import './faq.css'
import SocialSection from './SocialSection';


import Header from './Header';
let DEFAULT_LOCALE = "en-us"

const QuestionAnswer = (props) =>{
    return(
        <div className="flexColumn QAcontainer">
            <div className="questionContainer">{props.question}</div>
            <div className="answerContainer">{props.answer}</div>
        </div>
    )
}

const FAQTitle = (props) =>{
    return (
        <div className="FAQtitle">
            Frequently Asked Questions
        </div> 
    )
}

const TermsAndConditions = (props) =>{
    return (
        <div className="termsContainer">
            <div className="termsIntro">In an effort to accelerate the development and implementation of solutions developed in this event, all products and solutions developed in this event will be open source. All products and solutions developed during this event will be subject to the terms below:</div>
            <div className="termsBody">
    The following terms apply to participation in the Puerto Rico COVID Ideathon ("Ideathon"). Entrants may create original solutions, prototypes, datasets, scripts, or other content, materials, discoveries or inventions (a "Submission"). The Ideathon is organized by the Puerto Rico COVID Ideathon Planning Committee.
    <br/><br/>
    Entrants retain ownership of all intellectual and industrial property rights (including moral rights) in and to Submissions.
    <br/><br/>
    As a condition of submission, Entrant grants the Ideathon Organizer, its subsidiaries, agents and partner companies, without restrictions, a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to use, reproduce, adapt, modify, publish, distribute, publicly perform, create a derivative work from, and publicly display the Submission.
    <br/><br/>
    Entrants provide Submissions on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied, including, without limitation, any warranties or conditions of TITLE, NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR PURPOSE.
    <br/><br/>
    Entrant represents and warrants that, to the best of his or her knowledge, any work product is Entrant’s own original work and is not within the intellectual property rights of any third party, including any former or current employers. If you are unsure, you should consult any former or current employment agreement to which you are a party. Under no circumstances will Ideathon Organizer be liable to you or any third party for any damages, direct or otherwise, arising out of use of this ideathon work product.            
            </div>            
        </div>

    )
}

const MainSection = (props) =>{
    return (
    <div className="flexColumn">
        <QuestionAnswer
            question="What is an ideathon💡? What do I have to do?"
            answer="An ideathon is an event in which participants develop solutions for a select problem space or themes. Participants are usually assigned to a particular theme or track and then form a team within the track. The team then spends the weekend developing an idea to solve a particular problem within the track. The ideathon ends with the team presenting the idea to a panel of judges through a five minute pitch. In this case, we have chosen three problem spaces or tracks: <health>, <economy>, and <education>." />
        <QuestionAnswer
            question="What are the requirements to participate?"
            answer="There are no requirements✅- just have an internet connection📶 and a computer💻 during the weekend."/>           
        <QuestionAnswer
            question="Do I need a technical background to apply? "
            answer="Not at all! The only thing a team has to deliver is a 5-minute pitch. We encourage people of diverse professional, academic, and geographic backgrounds to apply."/>  
        <QuestionAnswer
            question="Do I apply individually🙋‍♂️ or as a team?👨‍👩‍👧‍👦"
            answer="Each person applies individually. There is no team application. We encourage participants to forms teams during the team matching process of each track in the beginning of the event."/>
        <QuestionAnswer
            question="How will team👨‍👩‍👧‍👦 matching be done?" 
            answer="We encourage organic team matching. You’ll have the opportunity to message your track's Slack channel with your interests and find people also looking for a team! If you can’t find a team that way, we’ll help you out."/>       
        <QuestionAnswer
            question="¿Necesito hablar inglés para participar?"
            answer="¡Acho, no te preocupes! Puedes hacer tu pitch en español o inglés- como más te sientas cómod@."/>         
     <QuestionAnswer
            question="How much time🕓 do I need to commit?"
            answer="The ideathon is a 48-hour sprint beginning on Friday afternoon (January 15) and ending on Sunday afternoon (January 17). Since the goal is to rapidly bring meaningful solutions to life, you should expect to commit Friday evening, most of Saturday, and Sunday morning/early afternoon. You do NOT need to be available 24/7 throughout the event, however you should communicate your availability to team members"/>        
       <QuestionAnswer
            question="When will I hear back about my application 🗓? "
            answer="Our team will review applications on a rolling basis.  We will do our best to respond to these periodically. The application deadline has been pushed from Monday, January 11th to Thursday, January 14th at 11:59pm AST."/>                    
        <QuestionAnswer
            question="When are teams decided?"
            answer="Teams are finalized early on the day 2️⃣ of the ideathon. There will be specific opportunities for team matching on the day 1️⃣."/>   
        <QuestionAnswer
            question="How will my team👨‍👩‍👧‍👦 present its pitch?"
            answer="Your team will be given exactly five minutes to present their pitch on the afternoon of Sunday, January 17th. If you want to use a slide deck, you must use Google Slides. You will hand in your presentation link around Sunday at noon (make sure it visible to everyone).  We will control and advance slides on your command." />          
        <QuestionAnswer
            question="Do I have to make my pitch in English or Spanish?"
            answer="We encourage teams to present their pitch in whichever language they feel most comfortable pitching in (English or Spanish)."/>          
        <QuestionAnswer
            question="Will there be time for questions 🙋‍♀️?"
            answer="Judges may ask a few questions. Please keep your answers brief." />             
        <QuestionAnswer
            question="What are the prizes🏆? "
            answer="There will be prizes💰 for the best team for each of the three tracks in the form of $500. There will also be special prizes, for example, 'Best Pitch'. More to come soon."/>          
        <QuestionAnswer
            question="Do I need to have an idea beforehand?"
            answer="We encourage participants to think about a few ideas. However, you'll work with your team throughout the event to craft your solution and pitch."/>            
         <QuestionAnswer
            question="Do I choose my track?"
            answer="You rank your track preferences📊 on the application. We will try our best to match you to your first preference while maintaining fairly equal participant assignment among tracks. "/>                      
         <QuestionAnswer
            question="Do I need to be from Puerto Rico?"
            answer="We want to attract people interested and passionate about 🇵🇷. You can be from anywhere in the world🌎! "/>              
    <QuestionAnswer
            question="Who owns the solutions teams create?"
            answer={<TermsAndConditions/>}/>                          
        <QuestionAnswer
            question="Still have questions?"
            answer="Feel free to reach out to us through Facebook Messenger📧 below!"/>                                          
    </div>)

}

const FAQPage = (props) => {

    const [locale,setLocale] = useState(DEFAULT_LOCALE)

    return (
        <div className="flexColumn container">
            <Header setLocale={setLocale} locale={locale}/>
            <FAQTitle/>
            <MainSection locale={locale}/>
            <SocialSection/>
        </div>
    )
}

export default FAQPage