import React,{useState} from 'react';
import {ChevronsToRight} from './Chevrons';
// npm install --save-dev @iconify/react @iconify-icons/oi
import { Icon } from '@iconify/react';
import homeIcon from '@iconify-icons/oi/home';
import threeBars16 from '@iconify-icons/octicon/three-bars-16';
import cancelIcon from '@iconify-icons/topcoat/cancel';

import './navbar.css';

import localizableStrings from './localizableStrings';

import { useHistory } from "react-router-dom";

let SPANISH_LOCALE = "es-pr"
let ENGLISH_LOCALE = "en-us"


const ShowMoreButton = (props)=>{
    const iconSize = '1.25em';
    return(
    !props.engagedNavbar ?
        <Icon onClick={props.onClick} className="showMoreButton" width={iconSize} icon={threeBars16} color="white" />    
        :
        <Icon onClick={props.onClick} className="showMoreButton" width={iconSize} icon={cancelIcon} color="white" />)
}

const NavBar = (props) =>{
    const history = useHistory();
    const shouldUseMobileView = () => window.screen.width < 760;

    const [mobileViewEngaged,setMobileViewEngaged] = useState(shouldUseMobileView());
    const [engagedNavbar,setEngagedNavbar] = useState(false);

    const navBarClasses = "flexRow navbar"
    const engagedNavBarClasses = `${navBarClasses} engagedNavbar`
    const disengagedNavBarClasses = `${navBarClasses} disEngagedNavbar`
    const navItemsContainarClasses = "flewRow navBarItemsContainer"
    const engagedNavItemsContainerClasses = `${navItemsContainarClasses} engagedNavItemsContainer`
    const disEngagedNavItemsContainerClasses = `${navItemsContainarClasses} disEngagedNavItemsContainer`

    let handleResize = (e) =>{
        setMobileViewEngaged(shouldUseMobileView());
    }
    window.addEventListener("resize", handleResize);

    return (
        <div className={!mobileViewEngaged ? navBarClasses : (engagedNavbar ? engagedNavBarClasses : disengagedNavBarClasses)}>
            <div className="flexRow logoTitle" onClick={()=>history.push('/')}>PR COVID Ideathon</div>            
            <div className={!mobileViewEngaged ? 
                navItemsContainarClasses: 
                    engagedNavbar ? engagedNavItemsContainerClasses : disEngagedNavItemsContainerClasses}>
                <div className="flexRow navbarItem" onClick={()=>history.push('/')}>
                    <Icon icon={homeIcon} color="white" />
                    Home
                    </div>
                <div className="flexRow navbarItem" onClick={()=>history.push('/faq')}>F.A.Q.</div>
                <div className="flexRow navbarItem" onClick={()=>history.push('/schedule')}>{localizableStrings[props.locale].schedule}</div>
                <div className="flexRow navbarItem" onClick={()=>history.push('/about')}>{localizableStrings[props.locale].aboutUs}</div>
                <div className="flexRow navbarItem glowingText" onClick={()=>history.push('/nextsteps')}>{localizableStrings[props.locale].nextSteps}</div>

                <div className="flexRow localesContainer" > 
                    {!props.localesDisabled ?
                        (<>
                        <div className="localeButton" onClick={() => props.setLocale(ENGLISH_LOCALE)}>EN</div>
                        <div  className="localeButton" onClick={() => props.setLocale(SPANISH_LOCALE)}>ES</div></>)
                        : <></>}
                </div>                
            </div>
            <ShowMoreButton engagedNavbar={engagedNavbar} onClick={()=>setEngagedNavbar(!engagedNavbar)}/>                          
        </div>)
}



const Header = (props) =>{

    return (
        <>
            <NavBar setLocale={props.setLocale} locale={props.locale}/>
            <ChevronsToRight/>
            <div className="flexColumn typingTitle" id="minimalisticBorder">
                <div className="typingAnimationFirst threeDEffectBlack"><em>Puerto Rico</em></div>
                <div className="typingAnimationFirst threeDEffectBlack"><i>COVID Ideathon</i></div>
            </div>
        </>        
    )
}

export default Header;
