import React from 'react';
// Sponsor Logos
import piloto151logo from './sponsors/piloto151.png'
import moonsailCapitalLogo from './sponsors/moonsail_capital.png'
import morroVenturesLogo from './sponsors/morro_ventures.png'
import nagnoiLogo from './sponsors/nagnoi.png'
import ponceHealthLogo from './sponsors/ponce_health_sciences.png'
import holbertonLogo from './sponsors/holberton.png'
import engine4Logo from './sponsors/engine4.png'
import HIClogo from './sponsors/HIC.png'
import parallel18Logo from './sponsors/parallel18.png'
import uncommonLogo from './sponsors/uncommon_entrepreneurs.png'
import maceiraLogo from './sponsors/maceira_attorneys.png'
import elcomebackLogo from './sponsors/elcomeback.png'
import elforoLogo from './sponsors/elforo.png'
import emprendedoresLogo from './sponsors/emprendedores.png'
import uprmEshipLogo from './sponsors/uprmeship.png'

import localizableStrings from './localizableStrings';




const SponsorsSection = (props) =>{

    const SponsorImage = (props) => <img {...props} alt={props.alt} className="sponsorLogo" onClick={() =>window.open(props.href,'_blank')}/>

    return(
        <div className="flewColumn sponsorsSection">
            <div style={{fontSize:15,fontWeight:'bold',marginTop:10,color:'black'}}>{`${localizableStrings[props.locale].ourSponsors}:`}</div>
            <div id="sponsorRow">
                <SponsorImage alt="Morro Ventures" src={morroVenturesLogo} href="https://morroventures.com/"/>
                <SponsorImage alt="Moonsail Capital" src={moonsailCapitalLogo} href="https://moonsailcapital.com/"/>
                <SponsorImage alt="Holberton School" src={holbertonLogo} href="https://www.holbertonschool.com/campus_life/san_juan"/>            
                <SponsorImage alt="Healthcare Innovation Center" src={HIClogo} href="http://hcicenter.com/"/>
            </div>
            <div id="sponsorRow" >
                <SponsorImage alt="Nagnoi" src={nagnoiLogo} href="https://www.nagnoi.com/"/>
                <SponsorImage alt="Engine 4" src={engine4Logo} href="https://engine-4.com/"/>
                <SponsorImage alt="Piloto 151" src={piloto151logo} href="https://piloto151.com/"/>     
                <SponsorImage alt="UPRM Eship Network" src={uprmEshipLogo} href="https://www.uprm.edu/eship/"/>                            
            </div>
            <div id="sponsorRow">
                <SponsorImage alt="Ponce Health Sciences University" src={ponceHealthLogo} href="https://www.psm.edu/"/>            
                <SponsorImage alt="Centro Para Emprendedores" src={emprendedoresLogo} href="https://www.centroparaemprendedores.org/"/>
                <SponsorImage alt="Parallel18" src={parallel18Logo} href="https://www.parallel18.com/"/>
                <SponsorImage alt="Uncommon Entrepreneurs" src={uncommonLogo} href="https://Facebook.com/uncommonentrepreneurs/"/>
            </div>
            <div id="sponsorRow">
                <SponsorImage alt="Maceira Attorneys at Law" src={maceiraLogo} href="https://www.prCOVIDideathon.com"/>        
                <SponsorImage alt="El Foro de Puerto Rico" src={elforoLogo} href="https://www.elforodepuertorico.com/"/>
                <SponsorImage alt="El Comeback" src={elcomebackLogo} href="https://www.elcomebackpr.org/"/>
            </div>   
        </div>
    )
}

export default SponsorsSection;