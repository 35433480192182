import simpleCoding from './winners/simplecoding.png'
import gravity from './winners/gravity.png'
import coconet from './winners/coconet.png'
import kiosko from './winners/kiosko.png'
import phoenix from './winners/phoenix.png'
import bigcollegebros from './winners/bigcollegebros.png'
import delacasa from './winners/delacasa.png'
import covbusters from './winners/covbusters.png'
import './winners.css'
import Button from 'react-bootstrap/Button';


const WinningTeam = (props) =>{
    return(
        <div className="teamContainer flexColumn">
            <div className="teamTitle">{props.title}</div>
            <img className="teamImage" src={props.image} alt={`${props.title}`}/>
            <div className="teamDescription">{props.description}</div>
            {props.pitchLink ? <Button className="viewPitchButton" variant="info" onClick={() => window.open(props.pitchLink)}>{`View ${props.title}'s pitch`}</Button> : null}
        </div>
    )
}

const TrackWinners = (props)=>{
    var winningTeams = []
    for (var i=0; i<props.winningTeams.length; i++ ){
        let team = props.winningTeams[i]
        winningTeams.push(<WinningTeam key={i} image={team.image} title={team.title} description={team.description} pitchLink={team.pitchLink}/>)
    }

    return (
        <div className="flexColumn trackContainer">
            <div className="trackTitle">{props.trackName}</div>
            <div className="flexRow winnersRow">
                {winningTeams}
            </div>

        </div>
    )
}

const WinnersSection = (props) =>{
    let healthTeams = [{title:"Team Gravity",pitchLink:"https://fb.watch/3WxfmYJr71/", image:gravity,description:"We created an app for successful registration and tracking of the people who wish to/have gotten the COVID vaccine. The app will include news, registration, notifications, pairing with vaccination locations, reporting of side effects, and more."},
                        {title:"Team Simple Coding",pitchLink:"https://fb.watch/3WxhESh5Xw/", image:simpleCoding,description:"Una aplicación dirigida a voluntarios y líderes comunitarios para ofrecer ayuda a las personas de mayor edad, solas o condiciones especiales durante esta pandemia. Será una conexión entre suplidores, voluntarios y las personas de edad avanzada."}] 
    let economyTeams = [{title:"Team Coconet",pitchLink:"https://fb.watch/3Wxgvy9NG4/", image:coconet,description:"A web platform that helps medical manufacturers find qualified service providers."},
                        {title:"Team Kiosko",pitchLink:"https://fb.watch/3Wx2jcvmxa/",image:kiosko,description:"Kiosko is an online marketplace that serves as a bridge between remote workers and underutilized real estate in Puerto Rico. Kiosko serves to promote remote worker tourism on the island, allowing additional revenue streams for local property owners who can now fully monetize their spaces. "}] 

    let educationTeams = [{title:"Team Phoenix",pitchLink:"https://fb.watch/3WxiUnSjxd/",image:phoenix,description:"Our goal is to transform the current educational system by unifying all educational activities into a single technological platform. This way all schools will have mastery of the existent technological tools for a better learning and teaching experience."},
                        {title:"Team Big College Bros",pitchLink:"https://fb.watch/3WxcGGJTpq/", image:bigcollegebros,description:"Proponemos una plataforma digital (website) para ayudar a los estudiantes de high school puertorriqueños en se trayectoria de educación post-secundaria en Puerto Rico."}]                         
    let specialPrizes = [{title:"Team De La Casa",pitchLink:"https://fb.watch/3WxltapMmO/",image:delacasa,description:"Our team aimed to aid the Puerto Rican economy by building a system that focuses on local and small businesses. The main goal is to put a spotlight towards these businesses and help local and tourists alike locate them."}]

    let peoplesChoice = [{title:"Team Cov-Busters",pitchLink:"https://fb.watch/3Wxklcd0Zs/",image:covbusters,description:"To combat the lack of accessibility to information and PPE, as well as misinformation, we have developed Regional Covid Care Centers, partnered by a web-app. With these two components of our solution, we can impact both parts of our population: those who have internet access and those who do not."}]

    return(
        <div className="winnersContainer flexColumn">
            <h2>👏🎉Congratulations🎉👏 to the winning teams!</h2>
            <h3>Check out their ideas presented in their own words.</h3>
            <div className="flexColumn">
                <TrackWinners trackName="<health>" winningTeams={healthTeams}/>
                <TrackWinners trackName="<economy>" winningTeams={economyTeams}/>
                <TrackWinners trackName="<education>" winningTeams={educationTeams}/>
                <TrackWinners trackName="<best technical prototype>" winningTeams={specialPrizes}/>
                <TrackWinners trackName="<people's choice prize>" winningTeams={peoplesChoice}/>
            </div>
            <Button className="viewAllPitchesButton" variant="primary" onClick={() => window.open("https://www.fb.com/prCOVIDideathon")}>See all of the event's pitches here!</Button>
        </div>
    )
}

export default WinnersSection;