// Social Logos

// yarn add @iconify/react @iconify-icons/logos
import { Icon, } from '@iconify/react';
import linkedinIcon from '@iconify-icons/logos/linkedin-icon';
import instagramFilled from '@iconify-icons/ant-design/instagram-filled';
import facebookRect from '@iconify-icons/brandico/facebook-rect';
import twitterIcon from '@iconify-icons/logos/twitter';

import ideathonLogo from "./ideathonLogo.png";
import messengerIcon from "./messenger_icon.png";

const CopyrightNotice = (props) =>{
    return(
        <div className="copyrightContainer flexColumn">
            <img className="ideathonLogo" src={ideathonLogo} alt="Puerto Rico COVID Ideathon"/>
            <div className="copyrightText">© Copyright 2021</div>
        </div>
    )
}

const MessengerButton = (props) =>{
    
    return (
        <div className="messengerIconContainer" onClick={()=>window.open("https://m.me/prcovidideathon")}>
            <img className="messengerIcon" alt="Facebook messenger chat icon" src={messengerIcon}/>
            <div>Chat with us!</div>        
        </div>   
    )
}

const SocialIcons = (props) =>{
    return (
        <div className="flexRow socialIconsContainer">
            <Icon className="socialIcon" width="2em" icon={linkedinIcon} onClick={()=>window.open("https://www.linkedin.com/events/puertoricocovidideathon-january6745547208344563712/",'_blank')} />
            <Icon className="socialIcon" width="2em" icon={instagramFilled} onClick={()=>window.open("https://www.instragram.com/prCOVIDideathon",'_blank')} />
            <Icon className="socialIcon" width="2em" icon={facebookRect} onClick={()=>window.open("https://www.facebook.com/prcovidideathon/",'_blank')} />
            <Icon className="socialIcon" width="2em" icon={twitterIcon} onClick={()=>window.open("https://www.twitter.com/covid_ideathon/",'_blank')} />
        </div>
        
    )
}

const SocialSection = (props) =>{
    return (
        <div className="flexColumn socialSection">
            <SocialIcons/>
            <MessengerButton/>
            <CopyrightNotice/>
        </div>
    )
}
export default SocialSection;