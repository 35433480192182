import React,{useState} from 'react';
import Header from './Header';
import SocialSection from './SocialSection';
import './aboutUs.css';
import { Icon, } from '@iconify/react';
import linkedinFilled from '@iconify-icons/ant-design/linkedin-filled';
import twitterIcon from '@iconify-icons/logos/twitter';

import williamPic from './committee/william.jpg';
import gabrielPic from './committee/gabriel.jpg';
import veronicaPic from './committee/veronica.jpg';
import adrianPic from './committee/adrian.jpg';
import oscarPic from './committee/oscar.jpg';
import margaretPic from './trackleads/margaret.png';
import carlosPic from './trackleads/carlos.png';


import localizableStrings from './localizableStrings';


let DEFAULT_LOCALE = "en-us";


const PlanningCommitteeDescription = (props) =>{
    return (
        <div className="committeeInfoContainer">
            <div className="committeeInfoTitle">{props.title}</div>
            <div className="committeeInfoDescription">{props.description}</div>

        </div>
    )
}

const SocialMediaRow = (props) =>{
    let iconWidth = "1em";
    return (
        <div className="flexRow socialIconRow">
            {props.twitterLink ?  <Icon className="socialIcon" width={iconWidth} icon={twitterIcon} onClick={() => window.open(props.twitterLink,'_blank') }/> : <></>}
            {props.linkedInLink ?  <Icon className="socialIcon" width={iconWidth} icon={linkedinFilled} onClick={() => window.open(props.linkedInLink,'_blank')}/>: <></>}
        </div>
    )
}

const Person = (props) =>{
    return(
        <div className="flexColumn profileContainer">
            <img className="profileImage" src={props.profileImage} alt={props.name}/>
            <div className="nameText">{props.name}</div>
            <div className="titleText">{props.title}</div>
            {props.subtitle ? <div className="titleText">{props.subtitle}</div> : <></>}

            <SocialMediaRow twitterLink={props.twitterLink} linkedInLink={props.linkedInLink}/>
        </div>
    )
}
function shuffleArray(array) {
    let curId = array.length;
    // There remain elements to shuffle
    while (0 !== curId) {
      // Pick a remaining element
      let randId = Math.floor(Math.random() * curId);
      curId -= 1;
      // Swap it with the current element.
      let tmp = array[curId];
      array[curId] = array[randId];
      array[randId] = tmp;
    }
    return array;
  }

const People = (props) =>{
    var people =  [
    <Person key={"g"} profileImage={gabrielPic} name="Gabriel Ginorio" title={localizableStrings[props.locale].planningCommittee} 
    twitterLink="https://www.twitter.com/covid_ideathon"
    linkedInLink="https://www.linkedin.com/in/gabrielginorio/"/>,            
    <Person key={"w"} profileImage={williamPic} name="William A. Rodríguez Jiménez" title={localizableStrings[props.locale].planningCommitteeLead}
        twitterLink="https://www.twitter.com/williamrodz"
        linkedInLink="https://www.linkedin.com/in/williamarodriguez"/>,
    <Person key={"v"} profileImage={veronicaPic} name="Veronica Jardón" title={localizableStrings[props.locale].planningCommittee}
        twitterLink="https://twitter.com/veronica_jardon"
        linkedInLink="https://www.linkedin.com/in/veronica-jardon-a41a4baa/"/>, 
    <Person key={"a"}profileImage={adrianPic} name="Adrián Pagán" title={localizableStrings[props.locale].planningCommittee}
        twitterLink="https://twitter.com/adrpagan"
        linkedInLink="https://www.linkedin.com/in/paganadrian/"/>,     
    <Person key={"o"}profileImage={oscarPic} name="Oscar Misla" title={localizableStrings[props.locale].planningCommittee}
        twitterLink="https://twitter.com/omisla_7"
        linkedInLink="https://www.linkedin.com/in/mislatorresoscar/"/> ];

    // Usage of shuffle
    people = shuffleArray(people);
    return (
        <div className="flexColumn peopleColumn">
            <div className="flexRow peopleRow">
                {[people[0], people[1], people[2]]}                      
            </div>
            <div className="flexRow peopleRow">
            {[people[3], people[4]]}                      
            </div>
        </div>    

    )
}

const TrackLeads = (props) =>{
    return (
        <div className="flexColumn peopleColumn trackLeadsContainer">
            <div className="committeeInfoTitle">{localizableStrings[props.locale].trackLeads}</div>
            <div className="flexRow peopleRow">

            <Person key={"g"} profileImage={carlosPic} name="Carlos Guevara" title={localizableStrings[props.locale].trackLead}
                subtitle={`<${localizableStrings[props.locale].health}>`}
                twitterLink="https://twitter.com/carlosguevara01"/>
            <Person key={"a"}profileImage={adrianPic} name="Adrián Pagán" title={localizableStrings[props.locale].trackLead}
                subtitle={`<${localizableStrings[props.locale].economy}>`}                
                twitterLink="https://twitter.com/adrpagan"
                linkedInLink="https://www.linkedin.com/in/paganadrian/"/>
            <Person key={"g"} profileImage={margaretPic} name="Margaret Andersen" title={localizableStrings[props.locale].trackLead}
                subtitle={`<${localizableStrings[props.locale].education}>`}                
                twitterLink="https://www.twitter.com/covid_ideathon"
                linkedInLink="https://www.linkedin.com/in/margaret-andersen-05632a12b/"/>                  
            </div>            
            
        </div>
    )
}

const AboutUsPage = (props) =>{
    const [locale,setLocale] = useState(DEFAULT_LOCALE);

    return(
        <div className="flexColumn container">
            <Header setLocale={setLocale} locale={locale}/>
            <PlanningCommitteeDescription
                title={localizableStrings[locale].planningCommittee}
                description={localizableStrings[locale].planningCommitteeDescription}/>
            <People locale={locale}/>
            <TrackLeads locale={locale}/>
            <SocialSection/>
        </div>        
    )
}

export default AboutUsPage;