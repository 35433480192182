import React,{useState} from 'react';
import './index.css'
import './schedule.css'

import SocialSection from './SocialSection';


import Header from './Header';
let DEFAULT_LOCALE = "en-us";


const ScheduleTitle = (props) =>{
    return (
        <div className="scheduleTitle">
            Official Schedule
        </div> 
    )
}

const TimeAndEvent = (props) =>{
    return(
        <div className="flewRow timeAndEvent">
            <div>{props.time}</div>
            <div className="eventDiv">{props.event}</div>
        </div>
    )
}


const Schedule = (props) =>{
    let currentDate = new Date();
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    // let cYear = currentDate.getFullYear();
    let itsFridayOfEvent = cDay === 15 && cMonth === 1;
    let itsSaturdayOfEvent = cDay === 16 && cMonth === 1;
    let itsSundayOfEvent = cDay === 17 && cMonth === 1;
    // let itsTestDay = cDay === 8 && cMonth === 1;

    let dateColumnClasses = "flexColumn dateColumn"
    let currentDateColumnClasses = `${dateColumnClasses} currentDateColumn`

    return (
    <div className="flexRow scheduleContainer" >   
    {/* Add "currentDateColumn" to the className of the dateColumn it currently is  */}
        <div className={itsFridayOfEvent ? currentDateColumnClasses : dateColumnClasses}>
            <div className="dayTitle">Day 1️⃣</div>
            <div className="dateTitle">Friday, January 15th, 2021</div>
            <TimeAndEvent time="5:00pm AST" event="Opening Ceremony"/>
            <TimeAndEvent time="~5:45pm AST" event='Ideathon kickoff. Team matching begins'/>
        </div> 
        <div className={itsSaturdayOfEvent ? currentDateColumnClasses : dateColumnClasses}>
            <div className="dayTitle">Day 2️⃣</div>
            <div className="dateTitle">Saturday, January 16th, 2021</div>
            <TimeAndEvent time="9:30am AST" event="Day 2 Sync Session"/>
            <TimeAndEvent time="12:00pm AST" event='Deadline to submit team rosters'/>
            <TimeAndEvent time="1pm AST" event={<> How to Pitch Workshop - Hosted by<a href="https://morroventures.com/"> Morro Ventures</a></>}/>
            <TimeAndEvent time="2pm AST" event={<> Business Model Canvas Workshop - Hosted by<a href="https://www.holbertonschool.com/campus_life/san_juan"> Holberton School</a></>}/>
            <TimeAndEvent time="8pm-9pm AST" event={<> Practice Pitch Sessions{/* <a href="https://docs.google.com/spreadsheets/d/1fyo_5GJuFJ2y0l8biJmyXP1DWVvHNLPX5iEqtpihECw/edit?usp=sharing">Please sign up for a timeslot here</a>*/}</>}/>
        </div> 
        <div className={itsSundayOfEvent ? currentDateColumnClasses : dateColumnClasses}>
            <div className="dayTitle">Day 3️⃣</div>
            <div className="dateTitle">Sunday, January 17th, 2021</div>
            <TimeAndEvent time="12:00pm AST" event="Pitch slide deck submission closes."/>
            <TimeAndEvent time="1:00pm AST" event='Pitches begin. Order will be <health>, <economy>, and <education>. Was livestreamed on Facebook'/>
            <TimeAndEvent time="~2:00pm AST" event='Anticipated start time of <economy> pitches.'/>
            <TimeAndEvent time="~3:00pm AST" event='Anticipated start time of <education> pitches.'/>
            <TimeAndEvent time="~5:00pm AST" event='Closing and Award Ceremony'/>

        </div>                                 
    </div>)

}

const SchedulePage = (props) => {

    const [locale,setLocale] = useState(DEFAULT_LOCALE)

    return (
        <div className="flexColumn container">
            <Header locale={locale} setLocale={setLocale} localesDisabled={true}/>
            <ScheduleTitle/>
            {/* <p style={{color:'yellow'}}>{'>>'}All virtual meetings and events will be held in <a href="">this meeting link{'<<'}</a></p> */}
            <Schedule locale={locale}/>
            <SocialSection/>
        </div>
    )
}

export default SchedulePage;