import React,{useState} from 'react';
import './index.css'
// import { Button } from 'react-bootstrap';

// import {ChevronsToLeft} from './Chevrons';
import Header from './Header';
import WhenAndWhere from './WhenAndWhere';
import SponsorsSection from './SponsorsSection';
import SocialSection from './SocialSection';
// import Countdown from './Countdown';

import localizableStrings from './localizableStrings';
let DEFAULT_LOCALE = "en-us"



const HealthDescription = (props) => {
    return(
<p>
            The distribution of the Pfizer-BioNTech and Moderna vaccines against COVID-19 began in December, yet the pandemic and its effects are far from over. Puerto Rico has a population of around 3 million people. Compared to other places in the world with comprehensive testing infrastructure, getting access to a COVID-19 test is relatively hard. You will probably need a doctor’s prescription to get tested and will probably need to pay for it through insurance or other means, while in some places of the world testing is free and frequent.

Even though we are in the middle of a pandemic, many Puerto Ricans need to visit medical specialists. Whether it be for a dentist, orthopedic surgeon, or oncologist, many Puerto Ricans have experienced long waiting times in a room to be attended. With reduced capacity for waiting rooms, medical offices have been forced to think of new check-in processes or simply drop patients altogether. In addition, most doctors now prudently request a negative COVID-19 test within the last 72 hours as a prerequisite for an appointment-something that many people in more interior parts of the island simply do not have access to. 
<br/><br/>
How do we ensure vaccines are delivered to those who need them the most in the right order?
<br/>
How do we manage “leftover” vaccines so that they don’t go to waste?
<br/>
How do we continue to encourage mask wearing among the public? 
<br/>
What type of outreach program can be created in order to deliver vaccine education and doses to marginalized and secluded parts of the island? 
<br/>
When and how will we know when we reach herd immunity in Puerto Rico? 
<br/>
How can we incentivize vaccine adoption? 
<br/>
What commitment devices can be made to ensure that people remember to come in to take both doses of the vaccine?
<br/>
How should institutions treat people that have been vaccinated? Should these people show some type of certification in order to go through less restrictions? 
<br/>
How can we use technology and streamline the doctor’s appointment check-in process to minimize people being huddled in rooms for hours? 
<br/>
How can we incorporate digital systems to eliminate or supplement the need for a physical check in form when you visit a new doctor? 
<br/>
How can we reduce burnout among first responders? 
<br/>
How can we confidently transition into a future where COVID-19 is no longer a stressor on Puerto Rico’s health sector?
                
            </p>        
    )
}

const TrackDescription = (props) =>{
    const [descriptionViewable, setDescriptionState] = useState(false) 

    return(
    <div className="flexColumn">
        <div className="trackTitle" onClick={()=>setDescriptionState(!descriptionViewable)}>{props.title}</div>
        {descriptionViewable ? props.description : <></>}
    </div> 
    )
}

const EconomyDescription = (props) =>{
    return(
<p>
            The economic and social disruption caused by the pandemic is devastating: tens of millions of people are at risk of falling into extreme poverty, while the number of undernourished people, currently estimated at nearly 690 million, could increase by up to 132 million by the end of the year.
<br/><br/>

Millions of enterprises face an existential threat. Nearly half of the world’s 3.3 billion global workforce are at risk of losing their livelihoods. Informal economy workers are particularly vulnerable because the majority lack social protection and access to quality health care and have lost access to productive assets. Without the means to earn an income during lockdowns, many are unable to feed themselves and their families. For most, no income means no food, or, at best, less food and less nutritious food.  
<br/>
The pandemic has been affecting the entire food system and has laid bare its fragility. Border closures, trade restrictions and confinement measures have been preventing farmers from accessing markets, including for buying inputs and selling their produce, and agricultural workers from harvesting crops, thus disrupting domestic and international food supply chains and reducing access to healthy, safe and diverse diets. The pandemic has decimated jobs and placed millions of livelihoods at risk. As breadwinners lose jobs, fall ill and die, the food security and nutrition of millions of women and men are under threat, with those in low-income countries, particularly the most marginalized populations, which include small-scale farmers and indigenous peoples, being hardest hit.
<br/><br/>
how do we safely jumpstart tourism with vaccines? 
<br/>
how can we incorporate renewable energy to business as when we go back to “normal”?
<br/>
how do we promote Puerto Rico as a remote work destination?
<br/>
how do we get more government agencies to use online forms and paperwork?
<br/>
how do we get food deliveries to minimize the use of single use plastics for people who don’t need them? 
<br/>
how can create safe shared  commute options for people who cannot work remotely?  
<br/>
how do we get more restaurants to incorporate online ordering systems?

            </p>        
    )
}



const EducationDescription = (props) =>{
    return (
        <p>
The impact COVID-19 has had on public education infrastructure, private school organizations, universities, etc.. has been beyond imaginable. Both private and public schools never imagined to cease in person operations, hence they lacked the access to imperial resources that would aid in the transition to a remote classroom. Students and professors alike have had to face uncertainty amongst this pandemic which in many cases comes with challenges that they alone can’t work through. 
<br/>
Students are not only struggling to learn through virtual classrooms, they can’t see their friends, can’t go to lunch together where they would normally distract themselves and recharge their batteries. Teachers on the other hand are having to teach classes and at the same time self teach new techniques to maintain students engaged while providing impactful lessons, many times prioritizing their students development before their own physical and mental health. 
<br/>
It is no secret that COVID-19 will spark a revolution on how people learn, teach, and become professionals, and even how to become better people. It is up to us to speed that learning curve and help with the transition in an effort to avoid permanent damage among students and teachers alike. Some key questions that we feel would help in this transition are:
<br/><br/>
How do we improve access to a quality, public education in Puerto Rico?
<br/>
How can we keep students engaged? 
<br/>
How can we connect children with their friends and substitute “hallway” conversations? 
<br/>
What tools are teachers missing that restrain them from fully focusing on their pedagogical mission? 
<br/>
What are some ways that we can improve teacher/parent and teacher/students communications? 
<br/>
Are preparatory school students and college students getting the social life exposure they need to develop themselves? How can we make it better? 
<br/>
Are parents prepared to have their children back home and be full time remote students? Can we make a resource library to support them? 
<br/>
How can students be exposed to higher education? How are high school students being exposed to higher education programs? Do college students have the same opportunities as before to find research programs? How can students better reach their counselors for guidance? 

        </p>
    )
}

const TrackDescriptions = (props) =>{
    return(
        <div className="flexRow trackDescriptions">
            <TrackDescription title="<health>" description={<HealthDescription/>}/>
            <TrackDescription title="<economy>" description={<EconomyDescription/>}/>
            <TrackDescription title="<education>" description={<EducationDescription/>}/>
        </div>
    )
}

const MainSection = (props) => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    // const ActionButton =  (props) => <Button className="actionButton" variant="light" onClick={props.onClick} >{props.title}</Button>


    return (
        <div className='fadeInSlow' style={{fontWeight:'14px'}}>
            <div className="eventShortDescription">
                {localizableStrings[props.locale]["eventShortDescription"]}
            </div>
            <WhenAndWhere locale={props.locale}/>
            <div style={{fontWeight:'bold',textAlign:'center',marginTop:'3em'}}>{localizableStrings[props.locale].whoCanParticipate}</div>
            <div style={{textAlign:'center'}}>
            {localizableStrings[props.locale].whoCanParticipateAnswer}
            </div>                
            <div className="trackDescriptions">{`track descriptions:`}</div>
            <TrackDescriptions/>
            <div  className={props.selectedTopic ? "fadeInFast" : ""}>{props.selectedTopic ? localizableStrings[props.locale][props.selectedTopic] : ""}</div>
            {/* <div style={{display:'flex',flexDirection:'row',marginTop:45,alignItems:'center',justifyContent:'center'}}> 
                <div className="flexColumn">
                    <ActionButton onClick={()=>window.open("https://bit.ly/PRCOVIDIdeathonSignup",'_blank')} title={localizableStrings[props.locale].signup}/>
                    <ActionButton onClick={()=>window.open("https://bit.ly/PRCOVIDIdeathonMentor",'_blank')} title={localizableStrings[props.locale].beAMentor}/>
                    <ActionButton onClick={()=>window.open("https://bit.ly/PRCOVIDIdeathonSponsor",'_blank')} title={localizableStrings[props.locale].sponsorEvent}/> 
                </div>
                <ChevronsToLeft/>
            </div> */}
            <SponsorsSection locale={props.locale}/>
            <SocialSection/>            
        </div>
    )
}


const IdeathonPage = (props) => {

    const [locale,setLocale] = useState(DEFAULT_LOCALE)
    const [selectedTopic, setTopic] = useState()

    return (
        <div className="flexColumn container">
            {/* <Countdown locale={locale}/> */}
            <Header setLocale={setLocale} locale={locale}/>
            <MainSection setTopic={setTopic} selectedTopic={selectedTopic} locale={locale}/>
        </div>
    )
}

export default IdeathonPage