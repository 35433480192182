import mediaRecord from '@iconify-icons/oi/media-record';
import { Icon, } from '@iconify/react';

const Dots = (props) =>{

    const Dot = () => <Icon icon={mediaRecord} color="yellow" width="0.5em" />
    let rowStyle = {justifyContent:'space-evenly',alignItems:'space-evenly'};

    return (
        <div className="flexColumn dotsContainer">
            <div className="flexRow" style={rowStyle}>
                <Dot/>
                <Dot/>
                <Dot/>
            </div>
            <div className="flexRow" style={rowStyle}>
                <Dot/>
                <Dot/>
                <Dot/> 
            </div>      
            <div className="flexRow" style={rowStyle}>
                <Dot/>
                <Dot/>
                <Dot/>
            </div>
        </div>        
    )
}

export default Dots;