import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FrontPage from './FrontPage';
import FAQPage from './FAQPage';
import SchedulePage from './SchedulePage';
import AboutUsPage from './AboutUsPage';
import NextStepsPage from './NextStepsPage';



import { Switch, Route,BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize('G-FMQ9QT6V7H');

// const TestPage = (props) =>{
  
//   return (
//     <div style={{width:"10em",height:"10em",backgroundColor:'aqua'}}>      
//     </div>
//   )
// }

ReactDOM.render(
    <BrowserRouter>
      <Switch>
        {/*<Route path="/admin" component={AdminPage}/>*/}
        <Route path="/about" component={AboutUsPage}/>
        <Route path="/schedule" component={SchedulePage}/>
        <Route path="/faq" component={FAQPage}/>
        <Route path="/nextsteps" component={NextStepsPage}/>
        <Route path="/" component={FrontPage}/>
      </Switch>
    </BrowserRouter>,
  document.getElementById('root'));
