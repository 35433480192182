import React,{useState} from 'react';
import WinnersSection from './WinnersSection'
import SocialSection from './SocialSection';


import Header from './Header';
let DEFAULT_LOCALE = "en-us";


const NextSteps = (props) => {

    const [locale,setLocale] = useState(DEFAULT_LOCALE)

    return (
        <div className="flexColumn container">
            <Header locale={locale} setLocale={setLocale} localesDisabled={true}/>
            <WinnersSection/>
            <SocialSection/>
        </div>
    )
}

export default NextSteps;