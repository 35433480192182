import { Icon, } from '@iconify/react';
import chevronRight from '@iconify-icons/oi/chevron-right';
// import chevronLeft from '@iconify-icons/oi/chevron-left';

export const ChevronsToRight = (props) =>{
    return (
        <div className="flexRow">
            <Icon className="chevronToRight" key={1} icon={chevronRight} color="blue" width="4em" />
            <Icon className="chevronToRight" key={2} icon={chevronRight} color="blue" width="4em" />     
            <Icon className="chevronToRight" key={3} icon={chevronRight} color="blue" width="4em" />     
        </div>
    )
}

// export const ChevronsToLeft = (props) =>{
//     return (
//         <div className="flexColumn">
//             <Icon key={1} icon={chevronLeft} color="blue" height="4em" />
//             <Icon key={2} icon={chevronLeft} color="blue" height="4em" />     
//             <Icon key={3} icon={chevronLeft} color="blue" height="4em" />     
//         </div>
//     )
// }