
const localizableStringsSpanish = {
    health:"salud",economy:"economía",education:"educación",remoteWork:"trabajo remoto",
    eventShortDescription:"una actividad de 48 horas para acelerar la creación de soluciones nuevas para afrontar la crisis del covid-19 específicamente en Puerto Rico. durante tres días, participantes unieron en equipos para desarrollar una solución para un tema designado. al final, equpios presentaron sus soluciones a un jurado a través de un ‘pitch’ de 5 minutos. ",
    healthTrackDescription:"la distribución de vacunas ha empezado, pero la crisis y sus efectos aún perduran. ¿cómo aseguramos en estos meses que la vacuna le llegue a los que más la necesitan, en el orden adecuado? ¿cómo seguimos fomentando el uso de la mascarilla? ¿cómo sabremos cuando puerto rico alcance la imunidad de rebaño?", 
    economyTrackDescription:"¿cómo seguimos fomentando la adopción de carteras digitales en puerto rico? ¿cómo fomentamos a puerto rico como una destinación para trabajadores digitales? ¿qué soluciones podemos desarollar para asegurar la resiliencia de nuestra economía en futuros eventos?",
    environmentTrackDescription:"con menos movimento de personas, las emisiones de carbón han bajado, promoviendo la imagén de un ambiente más ecológicamente en balance. sin embargo, se he desatado una explosión en el uso de plásticos y materiales de un solo uso. restaurantes que han podido sobrevivir la crisis lo han hecho en gran parte al usar toneladas colectivas de envases plásticos o de styroforam. éstos luego terminan llenando vertederos en la isla. ¿cómo los restaurantes pueden inteligentemente realizar ordenes sin plásticos de un sólo uso? ¿cómo podemos incentivar el reciclaje o la composta y salvaguardar el manejo seguro de materiales potencialmente infectados? ",
    educationTrackDescription:"¿cómo modernizamos el sistema educativo público? ¿Como entrenamos maestros para entregar experiencias educativos cohesivas? ¿qué soluciones están disponibles para suplementar la experiencia educativa?",
    vaccineTrackDescription:"de haber una vacuna contra el COVID-19 acreditada por la comunidad científica y lista para distribución, ¿cómo será distribuida al pueblo puertorriqueño? poca vez en la historia se ha distribuido recursos al tal escala. ¿cómo deberán deben reaccionar lugares públicos? ¿será necesario públicamente certificar recepción de la vacuna? ¿cómo nos moveremos adelante como sociedad en tal punto crítico manteniendo protecciones en salud pública hacia grupos más frágiles?",
    chooseYourTrack:"escoge tu \"track\"",
    when:"¿cuándo?",eventDate:"15-17 de enero de 2021",location:"¿dónde?",eventLocation:"virtual",
    whoCanParticipate:"¿quién puede participar?",whoCanParticipateAnswer:"tuvimos una diversidad de participantes: estudiantes de escuela graduada, profesional, escuela superior, universidad, profesionales de diferentes niveles de experiencia, y 7 países y/o territorios representadas.",
    signup:"registraciones cerraron",beAMentor:"participa como mentor",sponsorEvent:"auspícianos",ourSponsors:"nuestros patrocinadores",
    planningCommittee:"Comité Organizador",planningCommitteeLead:"Comité Organizador, Director", planningCommitteeDescription:"Nuestro comité organizador es un grupo independientemente organizado, compuesto de puertorriqueños que no querían esperar hasta que terminara la pandemia del coronavirus para contribuir hacia la recuperación de Puerto Rico. Proveniendo de diferentes partes de la isla y de diferentes trasfondos académicos y profesionales, se unen por una pasión para ofrecer su tiempo para reconstruir su isla natal.",
    aboutUs:"Nosotros",schedule:"Itinerario",countdownTitle:"Bienvenidos, inovadores 🚀",nextSteps:"Ganadores 🚀",trackLead:"Líder de track",trackLeads:"Líderes por track",
}

const localizableStringsEnglish = {
    health:"health",economy:"economy",education:"education",remoteWork:"remote work",
    eventShortDescription:"a 48-hour virtual event that was held to develop solutions to the problems that COVID-19 has brought upon or exacerbated in Puerto Rico. teams worked together to develop a solution for one of the tracks and presented a pitch of their solution on the last day of the ideathon.",
    healthTrackDescription:"the distribution of vaccines has begun, yet this crisis and its effects are far from over. how do ensure vaccines are delivered to who needs them the most in the right order? how do we continue encouraging mask wearing? when and how will we know when we reach herd immunity in puerto rico?", 
    economyTrackDescription:"how do we continue encouraging the adoption of digital wallets in pr? how do we encourage puerto rico as a remote work destination post-covid? what solutions can be made to ensure the resiliency of our economy as a result of future events?",
    environmentTrackDescription:"con menos movimento de personas, las emisiones de carbón han bajado, promoviendo la imagén de un ambiente más ecológicamente en balance. sin embargo, se he desatado una explosión en el uso de plásticos y materiales de un solo uso. restaurantes que han podido sobrevivir la crisis lo han hecho en gran parte al usar toneladas colectivas de envases plásticos o de styroforam. éstos luego terminan llenando vertederos en la isla. ¿cómo los restaurantes pueden inteligentemente realizar ordenes sin plásticos de un sólo uso? ¿cómo podemos incentivar el reciclaje o la composta y salvaguardar el manejo seguro de materiales potencialmente infectados? ",
    educationTrackDescription:"how do we modernize puerto rico's public school system? how do we train teachers to offer the best of use resources? what solutions can be made to supplement the educational experience? how we provide resources such as laptops to students in sustainably affordable ways?",
    vaccineTrackDescription:"de haber una vacuna contra el COVID-19 acreditada por la comunidad científica y lista para distribución, ¿cómo será distribuida al pueblo puertorriqueño? poca vez en la historia se ha distribuido recursos al tal escala. ¿cómo deberán deben reaccionar lugares públicos? ¿será necesario públicamente certificar recepción de la vacuna? ¿cómo nos moveremos adelante como sociedad en tal punto crítico manteniendo protecciones en salud pública hacia grupos más frágiles?",
    when:"when",eventDate:"January 15-17,2021",location:"location",eventLocation:"virtual",
    chooseYourTrack:"choose your track",
    whoCanParticipate:"who could participate?",whoCanParticipateAnswer:"everyone was welcome to participate. we had high school, college, graduate and professional school students, professionals from different industries, and 7 countries and territories represented.",
    signup:"applications closed",beAMentor:"be a mentor",sponsorEvent:"sponsor us",ourSponsors:"our partners",
    planningCommittee:"Planning Committee",planningCommitteeLead:"Planning Committee, Lead", planningCommitteeDescription:"Our planning committee is an independently organized group of Puerto Ricans who did not want to wait until COVID-19 was over to help Puerto Rico overcome the challenges that the virus has aggravated on the island. Coming from different parts of the island and from different academic and professional backgrounds, they are united by a passion to volunteer their time to give back to the island that has raised them.",
    aboutUs:"About Us",schedule:"Schedule",countdownTitle:"Welcome, innovators 🚀",nextSteps:"Winning Ideas🚀",trackLead:"Track Lead",trackLeads:"Track Leads",
}

const localizableStrings = {
    "en-us":localizableStringsEnglish,
    "es-pr":localizableStringsSpanish
}

export default localizableStrings;